import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";
import numberWithCommas from "utils/numberFormatter";

const ImportExportCard = ({ response }) => {
  const { t } = useTranslation();
  const { totalImportVolume, totalExportVolume } = response;
  const setUpTrend = (amount) => {
    let a = amount ? amount * 100 : 0;
    return a + "%";
  };
  const data = [
    {
      title: t("Import") + " " + t("Volume"),
      metricType: "Volume",
      amount: totalImportVolume,
      change: response.trendImportVolume,
      changePercentage: setUpTrend(response.trendImportVolume),

      changeType: "negative",
    },
    {
      title: t("Export") + " " + t("Volume"),
      metricType: "Volume",
      amount: totalExportVolume,
      change: response.trendExpendVolume,
      changePercentage: setUpTrend(response.trendExpendVolume),

      changeType: "positive",
    },
    {
      title: t("Import") + " vs " + t("Export"),
      metricType: "Percentage",
      amount: ((totalImportVolume / totalExportVolume) * 100).toFixed(2) + "%",
      change: response.trendImport2Export,
      changePercentage: setUpTrend(response.trendImport2Export),

      changeType: "positive",
    },
  ];
  return (
    <>
      {data.map((d) => (
        <div className="dashboard-card dash-import-export-card">
          <section>
            <label className="dash-card-sec-title">
              {t(d.metricType || "")}
            </label>
            <div className="dash-card-title">{d.title}</div>
          </section>
          <section>
            <div className="dash-card-value-big-text mt-3">
              {numberWithCommas(d.amount || 0)}
            </div>
            <div
              className={
                "dash-card-trend " + (d.change <= 0 ? "negative" : "positive")
              }
            >
              {d.changePercentage}
              {d.change >= 0 ? <IoMdTrendingUp /> : <IoMdTrendingDown />}
            </div>
          </section>
        </div>
      ))}
    </>
  );
};

export default ImportExportCard;
