import EmaButton from "components/emaButton/EmaButton";
import React from "react";
import ActiveCard from "./dashboard-cards/ActiveCard";
import "./dashboard.sass";
import PieChartCard from "./dashboard-cards/PieChartCard";
import ImportExportCard from "./dashboard-cards/ImportExportCard";
import { chartData } from "./dashboard-cards/chartData";
import { getElements } from "services/apiEndpoints/Endpoints";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SelectableButton from "components/selectableButton/DropDownSelector";

const Dashboard = () => {
  const { t } = useTranslation();
  const [chartDashboard, setChartDashboard] = useState([]);
  const [exportCommodities, setExportCommodities] = useState([]);
  const [importCommodities, setImportCommodities] = useState([]);
  const [importExport, setImportExport] = useState({});
  const [timeSpan, setTimeSpan] = useState("4 weeks");
  const getDashboard = () => {
    getElements(`dashboard/main/actionable?period=${timeSpan}`).then(
      (response) => {
        setChartDashboard(response.data);
      }
    );
  };
  const getCommodities = () => {
    getElements(`dashboard/main/commodity?period=${timeSpan}`).then(
      (response) => {
        //console.log(response.data);
        const commodities = response.data?.commodityDashboards || [];
        let exports = [];
        let imports = [];
        commodities.sort((a, b) => {
          return b.commodityVolume - a.commodityVolume;
        });
        commodities.forEach((cmdty) => {
          if (cmdty.commodityVolume)
            cmdty.commodityVolume = (cmdty.commodityVolume * 100).toFixed(2);
          if (cmdty.isExport) exports.push(cmdty);
          else imports.push(cmdty);
        });

        setExportCommodities(exports);
        setImportCommodities(imports);
        setImportExport({
          totalExportVolume: response.data.totalExportVolume,
          totalImportVolume: response.data.totalImportVolume,
        });
      }
    );
  };

  useEffect(() => {
    getDashboard();
    getCommodities();
  }, [timeSpan]);
  return (
    <div className="dashboard">
      <div className="title-bar">
        <strong className="title-label">{t("Dashboard")}</strong>
        <div>
          <SelectableButton
            options={["4 weeks", "Quarter", "Year"]}
            value={timeSpan}
            onOptionSelected={(option) => {
              setTimeSpan(option);
            }}
          />
        </div>
      </div>
      <div className="dashboard-cards">
        <div className="dash-cards-flex-cont flex-column">
          <div className="dash-cards-flex-cont">
            <ActiveCard response={chartDashboard} />
          </div>
          <div className="dash-cards-flex-cont pie-charts-cont">
            <PieChartCard data={importCommodities} type="import" />
            <PieChartCard data={exportCommodities} type="export" />
          </div>
        </div>
        <div
          className="dash-cards-flex-cont flex-column"
          style={{ width: "20%" }}
        >
          <ImportExportCard response={importExport} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
