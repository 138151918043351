import { useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import "./CountryRegionInput.sass";
const CountryRegionInput = ({
  onChange,
  value,
  label,
  name,
  error,
  required,
  disabled,
}) => {
  //const [country, setCountry] = useState("United States");

  return (
    <FormGroup className="country-region-inputs ema-input ">
      {label && (
        <>
          <label>{label}</label>
          {required && <span className="required-red">*</span>}
        </>
      )}
      <div className="inputs-comp">
        <RegionDropdown
          country="Ethiopia"
          value={value}
          name={name}
          onChange={(val) => {
            onChange({ target: { value: val, name } });
          }}
          disabled={disabled}
          labelType="full"
          valueType="short"
        />
        <CountryDropdown
          value="Ethiopia"
          disabled
          /* onChange={(val) => {
            console.log(val);
            setCountry(val);
          }} */
          //whitelist={["ET"]}
        />
      </div>
      <Input type="hidden" invalid={Boolean(error)} />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

export default CountryRegionInput;
