import { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
//import FilterLabelBox from "./filterLabelBox/FilterLabelBox";
import EmaButton from "components/emaButton/EmaButton";
import "./filters.sass";
import { useTranslation } from "react-i18next";

const FilterComponent = ({
  title,
  request,
  setRequest,
  renderOptions,
  selected,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(false);

  const toggle = () => {
    setAnchorEl(!anchorEl);
  };

  const id = title.replace(/[^\w]/gi, "");

  return (
    <>
      <i
        className={`icon-Filter filter-toggler ${
          selected ? "darkGround" : ""
        } `}
        onClick={toggle}
        id={id}
      />
      {anchorEl && (
        <FilterDropDown
          request={request}
          setRequest={setRequest}
          renderOptions={renderOptions}
          toggle={toggle}
          id={id}
          t={t}
        />
      )}
    </>
  );
};

const FilterDropDown = ({
  request,
  setRequest,
  renderOptions,
  toggle,
  id,
  t,
}) => {
  const [state, setState] = useState(request);

  const onSave = () => {
    setRequest({ ...state, offset: 1 });
    toggle();
  };

  const cancelFilter = (item) => {
    if (Array.isArray(item)) {
      const modifiedObject = {};
      let isInRequest = false;

      //check if prop is already saved
      item.forEach((x) => {
        if (request[x]) isInRequest = true;
        modifiedObject[x] = "";
      });

      if (isInRequest) {
        setRequest({ ...request, ...modifiedObject, offset: 1 });
        toggle();
      } else {
        setState({ ...state, ...modifiedObject });
      }
    } else {
      if (request[item]) {
        setRequest({ ...request, [item]: "", offset: 1 });
        toggle();
      } else {
        setState({ ...state, [item]: "" });
      }
    }
  };

  return (
    <Popover placement="bottom" isOpen={true} target={id} toggle={toggle}>
      <PopoverBody>
        <div className="d-flex flex-column justify-content-center">
          {renderOptions(state, setState, cancelFilter)}
          <EmaButton title={t("Search")} btnDark sm onClick={onSave} />
        </div>
      </PopoverBody>
    </Popover>
  );
};
export default FilterComponent;
