import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { entryColumns } from "./entryColumns";
import expandedEntryColumn from "./ExpandedEntryColumn";
import { getElements } from "services/apiEndpoints/Endpoints";
import ReportFilter from "./ReportFilter";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import dateFormatter from "utils/dateFormater";
const EntryTable = ({ laneType }) => {
  const { t } = useTranslation();
  const [entryExpanded, setEntryExpanded] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [request, setRequest] = useState({
    origin: "",
    destination: "",
  });

  const Getentry = () => {
    const querystr = new URLSearchParams({
      origin: request.origin,
      destination: request.destination,
      lane_type: laneType,
    }).toString();

    getElements(`/reporting/commodity/volume?${querystr}`).then((res) => {
      setEntryExpanded(res.data);

      let _csvData = [];
      (res.data || []).forEach((com) => {
        let {
          commodity_type: commodity,
          air_total: air,
          tonnage_total: tonnage,
          truck_total: truck,
          rail_total: rail,
        } = com;

        _csvData.push({
          commodity,
          air,
          tonnage,
          truck,
          rail,
        });
        _csvData.push(
          ...(com.commodities || []).map((c) => ({
            ...c,
            commodity: ` ${commodity || ""}`,
          }))
        );
      });
      setCsvData(_csvData);
    });
  };

  useEffect(Getentry, [laneType, request.destination, request.origin]);

  const ExpandedComponent = ({ data }) => (
    <div className="ema-table short-table">
      <DataTable
        data={data.commodities}
        columns={expandedEntryColumn}
        noTableHead
        noHeader
        customStyles={{
          rows: {
            style: {
              background: "#f2f2f2",
            },
          },
        }}
      />
    </div>
  );

  const CSVheader = [
    { label: "Commodity", key: "commodity" },
    { label: "Tonnage", key: "tonnage" },
    { label: "Truck ", key: "truck" },
    { label: "Rail", key: "rail" },
    { label: "Air", key: "air" },
    { label: "Origin", key: "origin" },
    { label: "Destination", key: "destination" },
  ];

  return (
    <div className="operational-display">
      <div className="title-bar">
        <strong className="title-label">
          {t(`${laneType || ""} Origin & Destination`)}
        </strong>
        <div className="d-flex align-items-center">
          <CSVLink
            data={csvData}
            headers={CSVheader}
            filename={`OriginDestination-${dateFormatter(
              Date(),
              "YYYY-MM-DD-HH:mm"
            )}(UniTel).csv`}
            className="ema-btn btn-ema-dark mr-4"
            target="_blank"
          >
            Download Report
          </CSVLink>
          <ReportFilter request={request} setRequest={setRequest} t={t} />
        </div>
      </div>
      <div className="summary-list">
        <DataTable
          //columns={entryColumns}
          columns={entryColumns.map((c) => ({
            ...c,
            name: t(c.name),
          }))}
          data={entryExpanded}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default EntryTable;
