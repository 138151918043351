import React, { useEffect } from "react";
import EmaButton from "components/emaButton/EmaButton";
import { useState } from "react";
import OperatorForm from "./OperatorForm";
import { getElements, putElement } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";
import OperatorContact from "./operatorDetails/operator-contacts/OperatorContact";
import OperatorDocs from "./operatorDetails/operator-docs/OperatorDocs";
import { useTranslation } from "react-i18next";

const EditOperator = ({ setIsOpen, id, getOperators, disableInputs }) => {
  const { t } = useTranslation();
  const [operator, setOperator] = useState({});
  const [loading, setLoading] = useState(Loading);
  const [errors, setFormErrors] = useState([]);

  function handleChange(e) {
    setOperator({
      ...operator,
      [e.target.name]: e.target.value,
    });
  }
  function handleClickSave() {
    console.log(operator);
    putElement(`/Authority/Operator/${id}`, operator).then((res) => {
      if (res.success) {
        getOperators();
        setIsOpen(false);
      } else if (res.vErrors) {
        setFormErrors(res.vErrors);
      }
    });
  }

  const getSelectedOperators = () => {
    setLoading(Loading);
    getElements(`/Authority/Operator/${id}`).then((res) => {
      setOperator(res.data);
      setLoading(false);
    });
  };

  useEffect(getSelectedOperators, []);
  return (
    <div className="slider-panel">
      <div className="slider-header">
        <section className="slider-header-title">{operator.company}</section>
        <section className="slider-header-buttons">
          <EmaButton title={t("Cancel")} onClick={() => setIsOpen(false)} />
          <EmaButton
            title={t("Save")}
            btnDark
            onClick={() => handleClickSave(operator)}
            disabled={disableInputs}
          />
        </section>
      </div>
      <div className="slider-body">
        {loading ? (
          loading
        ) : (
          <>
            <OperatorForm
              handleChange={handleChange}
              operator={operator}
              errors={errors}
              disableInputs={disableInputs}
            />
            <OperatorContact operatorId={id} disableInputs={disableInputs} />
            <OperatorDocs operatorId={id} disableInputs={disableInputs} />
          </>
        )}
      </div>
    </div>
  );
};

export default EditOperator;
