import CellBuilder from "components/cell-builder/CellBuilder";
import SliderPanel from "components/sliderPanel/SliderPanel";
import React, { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import OperatorContactForm from "./OperatorContactForm";
import { getElements, postElement } from "services/apiEndpoints/Endpoints";
import joinStrings from "utils/joinStrings";

const OperatorContact = ({ operatorId, disableInputs }) => {
  const [contactOpen, setContactOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [errors, setFormErrors] = useState([]);

  const addContact = (contact, index) => {
    let modifiedContacts = JSON.parse(JSON.stringify(contacts));
    modifiedContacts.splice(index, 1, contact);
    updateContacts(modifiedContacts);
  };

  const deleteContact = (index) => {
    let modifiedContacts = JSON.parse(JSON.stringify(contacts));
    modifiedContacts.splice(index, 1);
    updateContacts(modifiedContacts);
  };

  const updateContacts = (modifiedContacts) => {
    postElement(
      `/Authority/Operator/${operatorId}/Contact`,
      modifiedContacts
    ).then((res) => {
      if (res.success) {
        getContacts();
        setContactOpen(false);
      } else if (res.vErrors) {
        setFormErrors(res.vErrors);
      }
    });
  };

  const getContacts = () => {
    getElements(`Authority/Operator/${operatorId}/contact`).then((res) => {
      setContacts(res.data?.data);
    });
  };

  useEffect(getContacts, []);
  return (
    <div>
      <div className="ema-card">
        <div className="ema-card-title d-flex align-items-center justify-content-between">
          <div>Contact</div>

          <i
            className={`mil-plus clickable ${disableInputs && "disabled"}`}
            onClick={() =>
              !disableInputs && setContactOpen({ index: contacts.length })
            }
            style={{ fontSize: "20px" }}
          />
        </div>

        <div className="summary-list">
          <DataTable
            data={contacts.map((item, ind) => {
              return { ...item, index: ind };
            })}
            columns={[
              {
                name: "Name",
                selector: (row) =>
                  CellBuilder(
                    joinStrings(
                      [row.firstName, row.middleName, row.familyName],
                      " "
                    ),
                    row.contactType
                  ),
                grow: 3,
              },
              {
                name: "Contact",
                selector: (row) => CellBuilder(row.primaryPhone, row.email),
                grow: 3,
              },
              {
                name: "Actions",
                cell: (row) => (
                  <>
                    <i
                      className={`icon-edit clickable mr-3 ${
                        disableInputs && "disabled"
                      }`}
                      style={{ fontSize: "18px" }}
                      onClick={() => !disableInputs && setContactOpen(row)}
                    />
                    <i
                      className={`icon-delete-o clickable ${
                        disableInputs && "disabled"
                      }`}
                      style={{ fontSize: "18px" }}
                      onClick={() => !disableInputs && deleteContact(row.index)}
                    />
                  </>
                ),
                grow: 1,
              },
            ]}
          />
        </div>
        <SliderPanel
          setIsOpen={setContactOpen}
          isOpen={contactOpen}
          from="bottom"
        >
          <OperatorContactForm
            setIsOpen={setContactOpen}
            addContact={addContact}
            index={contactOpen?.index}
            selectedContact={contactOpen}
            errors={errors}
            setFormErrors={setFormErrors}
          />
        </SliderPanel>
      </div>
    </div>
  );
};

export default OperatorContact;
