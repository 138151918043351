import EmaButton from "components/emaButton/EmaButton";
import { useEffect, useState } from "react";
import Table from "./Table";
import "assets/styles/summary-list-tables.sass";
import "./FreightOperators.sass";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SliderPanel from "components/sliderPanel/SliderPanel";
import NewOperator from "./NewOperator";
import { getElements } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";

import OperatorFilters from "./OperatorFilters";
import { useTranslation } from "react-i18next";
import OperatorDashboard from "./operator-dashboard/OperatorDashboard";

const FreightForwarders = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(Loading);
  const [operators, setOperators] = useState([]);

  const [request, setRequest] = useState({
    status: "",
    authority_type: "",
    tin: "",
    limit: 50,
    offset: 1,
  });
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const getOperators = () => {
    setLoading(Loading);

    const queryStr = new URLSearchParams({
      ...request,
      status: request.status.replaceAll(" ", ""),
    }).toString();

    getElements("Authority/Operator?" + queryStr).then((res) => {
      setOperators(res.data);
      setLoading(false);
    });
  };
  const filterOperators = () => {
    if (searchValue)
      getElements(`/Authority/Operator/Search?name=${searchValue || ""}`).then(
        (res) => {
          setOperators(res.data);
          setLoading(false);
        }
      );
    else {
      getOperators();
    }
  };
  useEffect(filterOperators, [searchValue]);
  useEffect(getOperators, [request]);
  return (
    <div className="freight-operators">
      <div className="title-bar">
        <strong className="title-label">{t("Freight Operators")}</strong>

        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center "
            style={{
              border: "1px solid #6B7280",
              borderRadius: "5px",
              width: "300px",
            }}
          >
            <i className="mil-search ml-2 mr-2 " style={{ color: "#6B7280" }} />

            <input
              type="text"
              placeholder="Search by Company"
              style={{ border: "none", outline: "none", width: "260px" }}
              className="py-1"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="ml-5">
            <OperatorFilters request={request} setRequest={setRequest} t={t} />
          </div>
          <EmaButton
            title={t("Freight Operator Registration")}
            btnDark
            icon="mil-plus"
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>
      <SliderPanel setIsOpen={() => {}} isOpen={isOpen}>
        <NewOperator setIsOpen={setIsOpen} getOperators={getOperators} />
      </SliderPanel>
      {loading ? (
        loading
      ) : (
        <>
          <div>
            <OperatorDashboard operatorDashboard={operators} />
          </div>
          <Table
            operators={operators}
            getOperators={getOperators}
            request={request}
            setRequest={setRequest}
          />
        </>
      )}
    </div>
  );
};

export default FreightForwarders;
