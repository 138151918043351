import "./SelectableButton.sass";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const SelectableButton = ({ options, onOptionSelected, value }) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="selectable-dropdown">
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <DropdownToggle caret>{t(value)}</DropdownToggle>

        <DropdownMenu>
          {options?.map((optionItem) => (
            <DropdownItem
              onClick={() => onOptionSelected(optionItem)}
              key={optionItem}
            >
              {optionItem}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SelectableButton;
