import CellBuilder from "components/cell-builder/CellBuilder";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { digitsToCurrency } from "utils/currencyFormatter";

import dateFormatter from "utils/dateFormater";

import SliderPanel from "components/sliderPanel/SliderPanel";
import EditMarketPlace from "./EditMarketPlace";
import CustomPagination from "components/custom-pagination/CustomPagination";
import BorderedText from "components/bordered-text/BorderedText";
import { useTranslation } from "react-i18next";
import EditOperator from "view/freight-operators/EditOperator";

const MarketplaceTable = ({ marketplace, request, setRequest }) => {
  const [openMarketplace, setOpenMarketplace] = useState(false);
  const [openOperator, setOpenOperator] = useState(false);
  const { t } = useTranslation();
  const columns = [
    {
      name: "Company",
      selector: (row) =>
        CellBuilder(
          <a
            className="link-like"
            onClick={() => setOpenOperator(row.operatorId)}
          >
            {row.company}
          </a>,
          row.tenderType
        ),
      grow: 4,
    },
    {
      name: "Shipment",
      selector: (row) => CellBuilder(row.shipmentNumber, row.commodity),
      grow: 4,
    },
    {
      name: "Mode",
      selector: (row) => CellBuilder(row.shipmentMode, row.trailerType),
      grow: 4,
    },
    {
      name: "Loading",
      selector: (row) =>
        CellBuilder(
          `${row.pickupLocation?.fullAddress || ""}`,
          dateFormatter(row.pickupDate, "DD MMM, HH:mm", true)
        ),
      grow: 4,
    },
    {
      name: "Unloading",
      selector: (row) =>
        CellBuilder(
          row.dropoffLocation?.fullAddress,
          dateFormatter(row.deliveryDate, "DD MMM, HH:mm", true)
        ),
      grow: 4,
    },
    {
      name: "Respond By",
      selector: (row) =>
        CellBuilder(dateFormatter(row.respondBy, "DD MMM YYYY", true)),
      grow: 3,
    },
    {
      name: "Distance",
      selector: (row) => CellBuilder(`${row.distance} km`, `${row.weight} kgs`),
      grow: 3,
    },
    {
      name: "Price",
      selector: (row) =>
        CellBuilder(
          digitsToCurrency(row.totalCharges),
          `${digitsToCurrency(row.ratePerDistance)} ${"/km"}`
        ),
      grow: 4,
      center: true,
    },
    {
      name: "Status",
      selector: (row) =>
        CellBuilder(
          <BorderedText
            text={row.status}
            classes={(row.status || "").toString().replaceAll(" ", "")}
          />,
          CellBuilder(row.age)
        ),

      grow: 2,
    },

    /* {
      name: "Actions",
      selector: () => (
        <div className="table-actions">
          <i
            className="mil-edit-o"
            style={{ cursor: "pointer", fontSize: "21px" }}
            onClick={() => setOpenMarketplace(true)}
          />
        </div>
      ),
      grow: 2,
      right: true,
    }, */
  ];
  return (
    <div className="summary-list">
      <DataTable
        columns={columns.map((c) => ({
          ...c,
          name: t(c.name || ""),
        }))}
        data={marketplace}
      />
      <CustomPagination
        request={request}
        setRequest={setRequest}
        nextExists={marketplace?.length >= request.limit}
      />
      <SliderPanel isOpen={openMarketplace} setIsOpen={setOpenMarketplace}>
        <EditMarketPlace setIsOpen={setOpenMarketplace} />
      </SliderPanel>
      <SliderPanel setIsOpen={setOpenOperator} isOpen={openOperator}>
        <EditOperator
          setIsOpen={setOpenOperator}
          id={openOperator}
          getOperators={() => {}}
          disableInputs
        />
      </SliderPanel>
    </div>
  );
};

export default MarketplaceTable;
