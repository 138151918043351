import React from "react";
import "./OperatorDashboard.sass";

const OperatorDashboard = ({ operatorDashboard }) => {
  let carriers = 0;
  let forwarders = 0;
  let shippers = 0;
  let fleetOwners = 0;
  operatorDashboard.forEach((item) => {
    switch (item.authorityType) {
      case "Carrier":
        carriers += 1;
        break;
      case "Forwarder":
        forwarders += 1;
        break;
      case "Shipper":
        shippers += 1;
        break;
      case "Fleet Owner":
        fleetOwners += 1;
        break;
    }
  });
  const data = [
    {
      label: "Carrier",
      count: carriers,
    },
    {
      label: "Forwarder",
      count: forwarders,
    },
    {
      label: "Shipper",
      count: shippers,
    },
    {
      label: "Fleet Owner",
      count: fleetOwners,
    },
  ];
  console.log(carriers);
  return (
    <div className="operator-dashboard">
      <>
        {data.map((operator, index) => (
          <section key={index} className="operator-dashboard-card">
            <div className="operator-dashboard-label">{operator.label}</div>
            <div className="operator-dashboard-count">
              {operator.count || 0}
            </div>
          </section>
        ))}
      </>
    </div>
  );
};

export default OperatorDashboard;
